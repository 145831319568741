<template>
  <div class="page-main-bg">
    <v-container class="main-container position-relative">
      <v-toolbar
        height="80px"
        id="page-tollbar"
        class="my-5 narrow-toolbar elevation-0"
      >
        <div class="page-title">
          <!--breadcrumbs-->
          <v-breadcrumbs class="pa-1 pt-3 breadcrumb" :items="breadcrumbs">
            <template v-slot:divider>
              <v-icon>mdi-chevron-left</v-icon>
            </template>
          </v-breadcrumbs>
          <v-toolbar-title class="d-flex align-center">
            <v-toolbar-title class="font-weight-bold"> الشكاوي</v-toolbar-title>
            <span class="sub-title mx-2"> {{ complains.length }} شكوي </span>
          </v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
      </v-toolbar>

      <!-- Loader -->

      <v-row class="mx-0" v-if="false">
        <v-col
          cols="12"
          class="property-col mb-2 shadow-border-radius px-0"
          v-for="i in 6"
          :key="i"
        >
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="list-item-avatar-two-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <!--no-complains-->

      <div v-if="false" class="no-complains">
        <h3>لا يوجد اعضاء مضافين لديك</h3>

        <v-btn rounded large color="success" @click.prevent="openAddDialog">
          <v-icon>mdi-plus</v-icon>
          <span class="tf mr-1">إضافة عضو</span>
        </v-btn>
      </div>

      <!--data-table-complains-->

      <v-row v-else>
        <dataTableItem
          :desserts="desserts"
          :headers="headersArr"
          @showDetails="setDetailes"
        />
      </v-row>

      <v-layout v-show="sideDetailes">
        <side-detailes @showDetails="setDetailes" />
      </v-layout>

      <div
        v-if="(this.complains || []).length > 10"
        class="text-center mt-6 mb-5"
      >
        <v-pagination v-model="page" :length="4" circle></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import dataTableItem from '../components/helper/dataTableItem'
import SideDetailes from '../components/task-manager/SideDetailes'

export default {
  components: { dataTableItem, SideDetailes },
  data: () => {
    return {
      sideDetailes: false,
      complains: [],
      breadcrumbs: [
        {
          text: 'التشغيل',
          disabled: false,
          exact: true,
          link: true,
          to: { path: '/dashboard/team' }
        },
        { text: 'الشكاوي', disabled: true }
      ],
      headersArr: [
        {
          text: 'إسم الشكوي',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'نوع الشكوي', value: 'calories' },
        { text: 'عقار', value: 'fat', align: ' text-center' },
        { text: 'موجهة من', value: 'carbs' },
        { text: 'اسم العميل', value: 'protein' },
        { text: 'موجهة إلي', value: 'carbs' },
        { text: 'الأولوية', value: 'priority' },
        { text: 'تاريخ الشكوي', value: 'protein' },
        { text: 'حالة المهمة', value: 'states', width: '90' },
        { text: '', value: 'actions' }
      ],
      desserts: [
        {
          name: 'التاخر في صيانه المياه',
          calories: 'إداره املاك',
          fat: 'عقار الحرمين - الرياض',
          carbs: 'ع أ',
          client: 'محمد احمد محمد',
          priority: '',
          states: 'في تقدم',
          protein: '١٢ مارس'
        },
        {
          name: 'تصريح بناء عقار الزيتون',
          calories: 'إداره املاك',
          fat: 'عقار الحرمين - الرياض',
          carbs: 'ع أ',
          client: 'محمد احمد محمد',
          priority: '',
          states: 'انتهي',
          protein: '١٢ مارس'
        },
        {
          name: 'تصريح بناء عقار الزيتون',
          calories: 'إداره املاك',
          fat: 'عقار الحرمين - الرياض',
          carbs: 'م م',
          client: 'محمد احمد محمد',
          priority: '',
          states: 'ان تفعل',
          protein: '١٢ مارس'
        },
        {
          name: 'التاخر في صيانه المياه',
          calories: 'إداره املاك',
          fat: 'عقار الحرمين - الرياض',
          carbs: 'م م',
          client: 'محمد احمد محمد',
          priority: '',
          states: 'انتهي',
          protein: '١٢ مارس'
        },
        {
          name: 'التاخر في صيانه المياه',
          calories: 'إداره املاك',
          fat: 'عقار الحرمين - الرياض',
          carbs: 'ع أ',
          client: 'محمد احمد محمد',
          priority: '',
          states: 'ان تفعل',
          protein: '١٢ مارس'
        },
        {
          name: 'التاخر في صيانه المياه',
          calories: 'إداره املاك',
          fat: '-',
          carbs: 'ع أ',
          client: 'محمد احمد محمد',
          priority: '',
          states: 'ان تفعل',
          protein: '١٢ مارس'
        },
        {
          name: 'التاخر في صيانه المياه',
          calories: 'إداره املاك',
          fat: 'عقار الحرمين - الرياض',
          carbs: 'ع أ',
          client: 'محمد احمد محمد',
          priority: '',
          states: 'في تقدم',
          protein: '١٢ مارس'
        },
        {
          name: 'التاخر في صيانه المياه',
          calories: 'إداره املاك',
          fat: 'عقار الحرمين - الرياض',
          carbs: 'ع أ',
          client: 'محمد احمد محمد',
          priority: '',
          states: 'في تقدم',
          protein: '١٢ مارس'
        },
        {
          name: 'التاخر في صيانه المياه',
          calories: 'إداره املاك',
          fat: '-',
          carbs: 'ع أ',
          client: 'محمد احمد محمد',
          priority: '',
          states: 'في تقدم',
          protein: '١٢ مارس'
        },
        {
          name: 'التاخر في صيانه المياه',
          calories: 'إداره املاك',
          fat: 'عقار الحرمين - الرياض',
          carbs: 'ع أ',
          client: 'محمد احمد محمد',
          priority: '',
          states: 'في تقدم',
          protein: '١٢ مارس'
        }
      ]
    }
  },
  created() {},
  computed: {},
  methods: {
    setDetailes(val) {
      this.sideDetailes = val
    }
  },
  metaInfo() {
    return {
      title: 'الشكاوي'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';
</style>
